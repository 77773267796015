import React, { useState,useEffect } from 'react';
import db from "./firebase/config"
import {onSnapshot, collection} from "firebase/firestore";

export default function App() {

  const [isOn,setIsOn] = useState(null);

  useEffect(
    () => 
      onSnapshot(collection(db,"power"),(snap) => {
        const res = snap.docs.map(doc => doc.data()); 
        setIsOn(res[0].isOn)
      }
      ),
    []
  );

  const styles = {
        display: "flex",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        background: isOn ? "#4CAF50" : "#F44336",
        fontSize: "4rem",
        color: "rgba(0,0,0,.3)",
        textAlign: "center",
        lineHeight: "1.6em"
  }

  return (
    <div style={styles}>
       {isOn
        ? <h1>Ready to use</h1>
        : <h1>Locked <br/><span style={{fontSize: ".4em"}}>Check-in first</span></h1>
       }
    </div>
  )
}
